import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.module.css';

const IconRadioButton = props => {
  const { checkedClassName } = props;
  return (
    <div>
      <svg className={props.className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={props.showAsRequired ? css.required : css.notChecked}
          cx="5"
          cy="19"
          r="6"
          transform="translate(2 -12)"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />

        <g
          className={classNames(css.checked, checkedClassName || css.checkedStyle)}
          transform="translate(2 -12)"
          fill="none"
          fillRule="evenodd"
        >
          <circle strokeWidth="2" cx="5" cy="19" r="6" />
          <circle fill="#FFF" fillRule="nonzero" cx="5" cy="19" r="3" />
        </g>
      </svg>
    </div>
  );
};

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <span className={props.className}>
      <span className={classNames(css.box, checkedClassName || css.checkedStyle)}>
        <svg style={{ fill: "transparent" }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 2C0 0.89543 0.895431 0 2 0H20C21.1046 0 22 0.895431 22 2V20C22 21.1046 21.1046 22 20 22H2C0.89543 22 0 21.1046 0 20V2Z" fill="white" />
          <path d="M0.75 2C0.75 1.30964 1.30964 0.75 2 0.75H20C20.6904 0.75 21.25 1.30964 21.25 2V20C21.25 20.6904 20.6904 21.25 20 21.25H2C1.30964 21.25 0.75 20.6904 0.75 20V2Z" stroke="black" strokeOpacity="0.6" strokeWidth="1.5" />
        </svg>
      </span>
      <span className={classNames(css.checked, checkedClassName || css.checkedStyle)}>
        <svg style={{ fill: "transparent" }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_i_2029_560)">
            <rect width="22" height="22" rx="2" fill="black" />
          </g>
          <g clipPath="url(#clip0_2029_560)">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.364 6.40733C17.5515 6.59486 17.6568 6.84917 17.6568 7.11433C17.6568 7.3795 17.5515 7.6338 17.364 7.82133L9.86867 15.3167C9.76962 15.4157 9.65202 15.4943 9.52259 15.548C9.39316 15.6016 9.25444 15.6292 9.11434 15.6292C8.97424 15.6292 8.83552 15.6016 8.70609 15.548C8.57666 15.4943 8.45906 15.4157 8.36001 15.3167L4.63601 11.5933C4.5405 11.5011 4.46431 11.3907 4.41191 11.2687C4.3595 11.1467 4.33191 11.0155 4.33076 10.8827C4.3296 10.75 4.3549 10.6183 4.40519 10.4954C4.45547 10.3725 4.52972 10.2608 4.62361 10.1669C4.71751 10.073 4.82916 9.99879 4.95205 9.94851C5.07495 9.89823 5.20663 9.87293 5.33941 9.87408C5.47219 9.87523 5.60341 9.90282 5.72541 9.95523C5.84742 10.0076 5.95776 10.0838 6.05001 10.1793L9.11401 13.2433L15.9493 6.40733C16.0422 6.31441 16.1525 6.24069 16.2738 6.19039C16.3952 6.1401 16.5253 6.11421 16.6567 6.11421C16.7881 6.11421 16.9181 6.1401 17.0395 6.19039C17.1609 6.24069 17.2711 6.31441 17.364 6.40733Z" fill="white" />
          </g>
          <defs>
            <filter id="filter0_i_2029_560" x="0" y="0" width="22" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2029_560" />
            </filter>
            <clipPath id="clip0_2029_560">
              <rect width="16" height="16" fill="white" transform="translate(3 3)" />
            </clipPath>
          </defs>
        </svg>

      </span>
    </span>
  );
};

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldRadioButtonComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    checkedClassName,
    id,
    label,
    showAsRequired,
    useSuccessColor,
    useCheckBox,
    variant,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className,variant ==  "blue" && css.blue);
  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};
  const disabledColorMaybe = rest.disabled
    ? {
      checkedClassName: css.checkedDisabled,
      boxClassName: css.boxDisabled,
    }
    : {};


  return (
    <span className={classes}>
      <Field {...radioButtonProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.radioButtonWrapper}>
          {useCheckBox ?
            <IconCheckbox
              className={svgClassName}
              {...successColorVariantMaybe}
              {...disabledColorMaybe}
              showAsRequired={showAsRequired}
            />
            :
            <IconRadioButton
              className={svgClassName}
              checkedClassName={checkedClassName}
              showAsRequired={showAsRequired}
            />
          }
        </span>
        <span className={css.text}>{label}</span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default FieldRadioButtonComponent;
