import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldSelect.module.css';
import IconCollection from '../IconCollection/IconCollection';

const FieldSelectComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    toolTipText,
    toolTipProfile,
    ...rest
  } = props;

  useEffect(()=>{
    const parser = new DOMParser();
    
    if(toolTipText?.type == "shoeSize"){
      const htmlstr = parser.parseFromString(toolTipText?.data, "text/html");
      const innerHTMLBody = htmlstr.body.innerHTML;
      const div = (typeof window !== "undefined" && window && window.document && window.document.createElement('div'));
      div.innerHTML = innerHTMLBody;
      (typeof window !== "undefined" && window && window.document && window.document.getElementById('tooltipshoe')?.append(div));
    }
    else if(toolTipText?.type == "condition"){
      const htmlstr = parser.parseFromString(toolTipText?.data, "text/html");
      const innerHTMLBody = htmlstr.body.innerHTML;
      const div = (typeof window !== "undefined" && window && window.document && window.document.createElement('div'));
      div.innerHTML = innerHTMLBody;
      (typeof window !== "undefined" && window && window.document && window.document.getElementById('tooltipcondition')?.append(div)); 
    }
    
  }, []);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames({
    [selectClassName]: selectClassName,
    [css.selectError]: hasError,
  });
  const handleChange = e => {
    input.onChange(e);
    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  const selectProps = { className: selectClasses, id, ...input, onChange: handleChange, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ?
        <label htmlFor={id}>
          {toolTipText ?
            <span className={css.iconHover}>
              <span className={css.tooltipWrapper}>
                <IconCollection name="info-icon" />
                {toolTipText.type == "shoeSize" ? 
               <span className={classNames(css.toolTipTextWrapper,css.mobileInfo)}>
                  <span id={'tooltipshoe'} className={css.toolTipText} />
                </span> : 
                <span className={classNames(css.toolTipTextWrapper,css.mobileInfo)}>
                  <span id={'tooltipcondition'} className={css.toolTipText}/>
                </span>}
                {/* <span className={css.arrow}></span> */}
              </span>
              &nbsp;{label}
            </span>
          : label}
          {toolTipProfile ?
            <span className={css.iconHover}>
              &nbsp; <span className={css.tooltipWrapper}>
               <IconCollection name="info-icon" />
                <span className={classNames(css.toolTipTextWrapper,
                 label == "Bundle Shipping" ? css.bundleShiping : ""
              )}>
                 <span className={css.title}> {toolTipProfile.title}</span>
                  <span className={css.description}>
                    {toolTipProfile.text}
                  </span>
                </span>
                <span className={css.arrow}></span>
              </span>
              &nbsp;
            </span>
          : null}
        </label> 
      : null}
      <select {...selectProps}>{children}</select>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

const { string, object, node } = PropTypes;

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldSelect = props => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
