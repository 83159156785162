import { fetchBrands } from "../../util/api";

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

export const FETCH_BRAND_REQUEST = 'app/AlgoliaSearchPage/FETCH_BRAND_REQUEST';
export const FETCH_BRAND_SUCCESS = 'app/AlgoliaSearchPage/FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_ERROR = 'app/AlgoliaSearchPage/FETCH_BRAND_ERROR';

const initialState = {
  fetchBrandsRequested: false,
  fetchBrandsSuccess: [],
  fetchBrandsError: null
};

export default function reducer(state=initialState, action={}){
  const { type, payload } = action;

  switch(type){
    case FETCH_BRAND_REQUEST:
      return { ...state, fetchBrandsRequested: true, fetchBrandsError: null };
    case FETCH_BRAND_SUCCESS:
      return { ...state, fetchBrandsRequested: false, fetchBrandsError: null, fetchBrandsSuccess: payload };
    case FETCH_BRAND_ERROR:
      return { ...state, fetchBrandsRequested: false, fetchBrandsError: payload };
    default:
      return state;
  }
};

export const brandsSaveRequest = requestAction(FETCH_BRAND_REQUEST);
export const brandsSaveSucess = successAction(FETCH_BRAND_SUCCESS);
export const brandsSaveError = errorAction(FETCH_BRAND_ERROR);


// export const fetchBrandsData = () =>(dispatch, getState, sdk)=>{
//   dispatch(brandsSaveRequest());
//   fetchBrands({}).then(res => {
//     const resp = res.data;
//     dispatch(brandsSaveSucess({ data: resp }));
//     return resp;
//   }).catch(err =>{
//     dispatch(brandsSaveError(storableError(err)));
//   });
// };

export const loadData = () => (dispatch, getState, sdk) =>{
  return Promise.all([
    // dispatch(fetchBrandsData()),
  ])
};