import { groupBy } from "lodash";
import { currentUserShowSuccess, fetchCurrentUser } from "../../ducks/user.duck";
import { denormalisedEntities, denormalisedResponseEntities, updatedEntities } from "../../util/data";
import { createImageVariantConfig } from "../../util/sdkLoader";
import { transactionLineItems } from "../../util/api";
import { storableError } from "../../util/errors";

export const SHOW_LISTING_BY_AUTHOR_REQUEST = 'app/CartPage/SHOW_LISTING_BY_AUTHOR_REQUEST';
export const SHOW_LISTING_BY_AUTHOR_SUCCESS = 'app/CartPage/SHOW_LISTING_BY_AUTHOR_SUCCESS';
export const SHOW_LISTING_BY_AUTHOR_ERROR = 'app/CartPage/SHOW_LISTING_BY_AUTHOR_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/CartPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/CartPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/CartPage/FETCH_LINE_ITEMS_ERROR';

export const QUANTITY_UPDATE_REQUEST = 'app/CartPage/QUANTITY_UPDATE_REQUEST';
export const QUANTITY_UPDATE_SUCCESS = 'app/CartPage/QUANTITY_UPDATE_SUCCESS';
export const QUANTITY_UPDATE_ERROR = 'app/CartPage/QUANTITY_UPDATE_ERROR';

export const REMOVE_ITEM_REQUEST = 'app/CartPage/REMOVE_ITEM_REQUEST'; 
export const REMOVE_ITEM_SUCCESS = 'app/CartPage/REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_ERROR = 'app/CartPage/REMOVE_ITEM_ERROR';

export const SHOW_USER_RATING_REQUEST = 'app/CartPage/SHOW_USER_RATING_REQUEST';
export const SHOW_USER_RATING_SUCCESS = 'app/CartPage/SHOW_USER_RATING_SUCCESS';
export const SHOW_USER_RATING_ERROR = 'app/CartPage/SHOW_USER_RATING_ERROR';

const initialState = {
  fetchListingByAuthorRequest: false,
  fetchListingByAuthorSuccess: null,
  fetchListingByAuthorError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  quantityUpdateRequest: false,
  quantityUpdateSuccess: false,
  quantityUpdateError: null,

  removeItemRequest: false,
  removeItemSuccess: false,
  removeItemError: null,
  userRatingRequest: false,
  userRating: null,
  userRatingError: null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LISTING_BY_AUTHOR_REQUEST:
      return { ...state, fetchListingByAuthorRequest: true };
    case SHOW_LISTING_BY_AUTHOR_SUCCESS:
      return { ...state, fetchListingByAuthorSuccess: payload, fetchListingByAuthorRequest: false };
    case SHOW_LISTING_BY_AUTHOR_ERROR:
      return { ...state, fetchListingByAuthorRequest: false, fetchListingByAuthorError: payload };
    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };
    case QUANTITY_UPDATE_REQUEST:
      return { ...state, quantityUpdateRequest: true };
    case QUANTITY_UPDATE_SUCCESS:
      return {...state, quantityUpdateRequest: false, quantityUpdateSuccess: true};
    case QUANTITY_UPDATE_ERROR:
      return {...state, quantityUpdateRequest: false, quantityUpdateSuccess: false, quantityUpdateError: payload};
    case REMOVE_ITEM_REQUEST:
      return {...state, removeItemRequest: true};
    case REMOVE_ITEM_SUCCESS:
      return {...state, removeItemRequest: false, removeItemSuccess: true};
    case REMOVE_ITEM_ERROR:
      return {...state, removeItemRequest: false, removeItemSuccess: false, removeItemError: payload};
    case SHOW_USER_RATING_REQUEST:
      return {...state, userRatingRequest: true, userRatingError: null };
    case SHOW_USER_RATING_SUCCESS:
      return {...state, userRatingRequest: false, userRating: payload, userRatingError: null};
    case SHOW_USER_RATING_ERROR:
      return {...state, userRatingRequest: false, userRating: null, userRatingError: payload };
    default:
      return state;
  }
};

export const showCartListingsRequest = searchParams => ({
  type: SHOW_LISTING_BY_AUTHOR_REQUEST,
  payload: {},
});

export const showCartListingsSuccess = response => ({
  type: SHOW_LISTING_BY_AUTHOR_SUCCESS,
  payload: response,
});

export const showCartListingsError = e => ({
  type: SHOW_LISTING_BY_AUTHOR_ERROR,
  error: true,
  payload: e,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const requestCartQuantityUpdate = ()=>({
  type: QUANTITY_UPDATE_REQUEST,
  payload: {}
});
export const successCartQuantityUpdate = (total)=>({
  type: QUANTITY_UPDATE_SUCCESS,
  payload: total
});
export const errorCartQuantityUpdate = (e)=>({
  type: QUANTITY_UPDATE_ERROR,
  error: true,
  payload: e
});

export const removeItemRequest = () =>({
  type: REMOVE_ITEM_REQUEST,
  payload: {}
});
export const removeItemSuccess = () =>({
  type: REMOVE_ITEM_SUCCESS,
  payload: {}
});
export const removeItemError = (e) =>({
  type: REMOVE_ITEM_ERROR,
  error: true,
  payload: e
});
export const showuserRatingsRequest = () =>({
  type: SHOW_USER_RATING_REQUEST,
  payload: {}
});
export const showuserRatingSuccess = (payload) =>({
  type: SHOW_USER_RATING_SUCCESS,
  payload: payload
});
export const showUserRatingError = (e) =>({
  type: SHOW_USER_RATING_ERROR,
  payload: e
});


export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, isOwnListing })
    .then(response => {
      const lineItems = response.data;;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      console.error(e, 'fetching-line-items-failed', {
        listingId: listingId,
        orderData,
      });
    });
};

export const getProperResponseOfListing = (response, config) => {
  const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
  const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "listing" })) : [];
  const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
  return responseArray;
};

export const showListingsByAuthor = params => async (dispatch, getState, sdk) => {
  dispatch(showCartListingsRequest());
  const currentUser = await dispatch(fetchCurrentUser());
  const cartData = (currentUser && currentUser.id && currentUser.attributes.profile.publicData.cartData) ?? [];
  const allListingIds = cartData.map(listing => listing.listingId);
  const allQuantities = cartData.reduce((acc, curr) =>{
    return acc += curr.quantity; 
  }, 0);
  const variantPrefix = 'listing-card';
  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };
  const listings = await sdk.listings.query({
    ids: allListingIds,
    include: ['author', 'images', 'author.profileImage', 'currentStock'],
    'fields.listing': [
      'title',
      'geolocation',
      'price',
      'publicData',
    ],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, 1),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, 1),
    // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'limit.images': 1,
  }, {expand: true}).then(async res => {

    const listings = await getProperResponseOfListing(res);
    const properResponseListingIds = listings.map(listing => listing.id.uuid);

    const ListingsIdsWithnoResponse = allListingIds.filter(id => !(properResponseListingIds.includes(id)));

    if(Array.isArray(ListingsIdsWithnoResponse) && ListingsIdsWithnoResponse.length){
      const updatedCartData = cartData.filter(obj => !ListingsIdsWithnoResponse.includes(obj.listingId));
      sdk.currentUser.updateProfile({
        publicData: { cartData: updatedCartData }
      }, {queryParams}).then(res =>{
        console.log("Successfully udpated the cart...");

        const entities = denormalisedResponseEntities(res);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
        
        return res;
      }).catch(err =>{
        console.log(err, "ERROR");
        throw new Error(err);
      });
    };

    const refinedListings = listings.map((list, index) => {
      const findListingFromCartData = cartData.find(listing => listing.listingId == list.id.uuid);
      return { ...list, quantity: findListingFromCartData.quantity };
    });

    const groupByListings = await groupBy(refinedListings, l => {
      const g = l.author.id.uuid;
      return g;
    });

    const authorIds = Object.keys(groupByListings);
    dispatch(showUserRatings(authorIds));

    return groupByListings;
  }).catch(err => dispatch(showCartListingsError(err)));

  await dispatch(showCartListingsSuccess({listings, cartItems: allQuantities}));

};

export const updateCartQuantity = params =>(dispatch, getState, sdk) =>{
  const {listingId, updatedQuantity, maxQuant } = params;
  dispatch(requestCartQuantityUpdate());

  const webState = getState();
  const currentUser = webState.user.currentUser;
  const { cartData } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
  const filterIdForCart = cartData.filter(listing => listing.listingId != listingId);
  const newQueryParams = {
    publicData: {
      cartData: [...filterIdForCart, {
        listingId,
        quantity: (updatedQuantity > maxQuant) ? Number.parseInt(maxQuant, 10) : (updatedQuantity < 1) ? 1 : Number.parseInt(updatedQuantity, 10),
        maxQuantities: maxQuant
      }]
    }
  };

  return sdk.currentUser.updateProfile({ ...newQueryParams }, {expand: true}).then(async res =>{
    if(res.status == 200){
      Promise.all([
        dispatch(successCartQuantityUpdate()), 
        dispatch(showListingsByAuthor())
      ]);
    }
  }).catch(err =>{
    console.log(err, "ERROR");
    dispatch(errorCartQuantityUpdate(err));
  })
};

export const removeItemFromCart = listingId =>(dispatch, getState, sdk)=>{
  dispatch(removeItemRequest());
  const webState = getState();
  const currentUser = webState.user.currentUser;
  const { cartData } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
  const { cartItems } = (currentUser && currentUser.id && currentUser.attributes.profile.privateData) || {};
  const cartWithOutTheListing = cartData.filter(listing => listing.listingId != listingId);
  const queryParams = {
    publicData: {
      cartData: [...cartWithOutTheListing]
    },
    privateData: {
      cartItems: cartItems == 1 ? 0 : (cartItems - 1),
    }
  };

  return sdk.currentUser.updateProfile({...queryParams}, {expand: true}).then(res =>{
    if(res.status == 200){
      return Promise.all([
        dispatch(removeItemSuccess()),
        dispatch(showListingsByAuthor())
      ])
    }
  }).catch(err =>{
    console.log(err, "ERROR");
    dispatch(removeItemError(err));
  })
};

export const showUserRatings = (authorIds) =>async(dispatch, getState, sdk) =>{
  dispatch(showuserRatingsRequest());

  const reviewRatings = Array.isArray(authorIds) && authorIds.length && await Promise.all(authorIds.map(authorId => {
  return sdk.reviews
      .query({
        subject_id: authorId,
        state: 'public',
        include: ['author', 'author.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
      .then(response => {
        const reviews = denormalisedResponseEntities(response);

        const reviewRatings = Math.round(Array.isArray(reviews) && reviews.length && reviews.reduce((acc, curr) => {
          return ((acc + curr.attributes.rating) / reviews.length).toFixed(1);
        }, 0));

        // dispatch(showuserRatingSuccess(reviewRatings));

        return { authorId, reviewRatings };
      })
      .catch(e => {
        dispatch(showUserRatingError(e));
        return e;
      });
  }));

  dispatch(showuserRatingSuccess(reviewRatings));

  // for (const authorId of authorIds) {
  //   return sdk.reviews
  //     .query({
  //       subject_id: authorId,
  //       state: 'public',
  //       include: ['author', 'author.profileImage'],
  //       'fields.image': ['variants.square-small', 'variants.square-small2x'],
  //     })
  //     .then(response => {
  //       const reviews = denormalisedResponseEntities(response);
        
  //       const reviewRatings = Math.round(Array.isArray(reviews) && reviews.length && reviews.reduce((acc, curr) => {
  //         return ((acc + curr.attributes.rating) / reviews.length).toFixed(1);
  //       }, 0));
        
  //       dispatch(showuserRatingSuccess(reviewRatings));

  //       return reviewRatings;
  //     })
  //     .catch(e => dispatch(showUserRatingError(e)));
  // }

};

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(showListingsByAuthor())]);
};